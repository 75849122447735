.wholeBoxs {
  width: 100%;
}

.wholeBoxs .wholeBoxs-content {
  padding: 0 0.333333rem;
  width: 100%;
  height: 10.015625rem;
  box-sizing: border-box;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-header .header-icon {
  background: url(https://images.tongtanggift.com/website/cases/self_case/gift-box.png?x-oss-process=image/format,webp);
  background-size: cover;
  background-repeat: no-repeat;
  width: 1.28125rem;
  height: 0.546875rem;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.260417rem;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left {
  display: flex;
  flex-direction: column;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img1 {
  position: relative;
  width: 3rem;
  height: 2.427083rem;
  margin-bottom: 0.166667rem;
  cursor: pointer;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img1 #imgUrl {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img1 .item-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img1 .item-img .item-img-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #2d2e33;
  opacity: .6;
  z-index: 95;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img1 .item-img .item-img-content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img1 .item-img .item-img-content .item-title {
  font-size: 0.125rem;
  color: #fff;
  text-align: center;
  padding: 0.104167rem 0.166667rem;
  border: 0.005208rem solid #fff;
  border-radius: 0.052083rem;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img2 {
  position: relative;
  width: 3rem;
  height: 1.739583rem;
  margin-bottom: 0.166667rem;
  cursor: pointer;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img2 #imgUrl {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img2 .item-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img2 .item-img .item-img-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #2d2e33;
  opacity: .6;
  z-index: 95;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img2 .item-img .item-img-content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img2 .item-img .item-img-content .item-title {
  font-size: 0.125rem;
  color: #fff;
  text-align: center;
  padding: 0.104167rem 0.166667rem;
  border: 0.005208rem solid #fff;
  border-radius: 0.052083rem;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img3 {
  position: relative;
  width: 3rem;
  height: 1.645833rem;
  margin-bottom: 0.166667rem;
  cursor: pointer;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img3 #imgUrl {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img3 .item-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img3 .item-img .item-img-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #2d2e33;
  opacity: .6;
  z-index: 95;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img3 .item-img .item-img-content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img3 .item-img .item-img-content .item-title {
  font-size: 0.125rem;
  color: #fff;
  text-align: center;
  padding: 0.104167rem 0.166667rem;
  border: 0.005208rem solid #fff;
  border-radius: 0.052083rem;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img4 {
  position: relative;
  width: 3rem;
  height: 1.875rem;
  cursor: pointer;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img4 #imgUrl {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img4 .item-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img4 .item-img .item-img-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #2d2e33;
  opacity: .6;
  z-index: 95;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img4 .item-img .item-img-content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-left .list-left-img4 .item-img .item-img-content .item-title {
  font-size: 0.125rem;
  color: #fff;
  text-align: center;
  padding: 0.104167rem 0.166667rem;
  border: 0.005208rem solid #fff;
  border-radius: 0.052083rem;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center {
  display: flex;
  flex-direction: column;
  margin: 0 0.166667rem;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img1 {
  position: relative;
  width: 3rem;
  height: 2.239583rem;
  margin-bottom: 0.166667rem;
  cursor: pointer;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img1 #imgUrl {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img1 .item-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img1 .item-img .item-img-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #2d2e33;
  opacity: .6;
  z-index: 95;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img1 .item-img .item-img-content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img1 .item-img .item-img-content .item-title {
  font-size: 0.125rem;
  color: #fff;
  text-align: center;
  padding: 0.104167rem 0.166667rem;
  border: 0.005208rem solid #fff;
  border-radius: 0.052083rem;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img2 {
  position: relative;
  width: 3rem;
  height: 2.239583rem;
  margin-bottom: 0.166667rem;
  cursor: pointer;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img2 #imgUrl {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img2 .item-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img2 .item-img .item-img-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #2d2e33;
  opacity: .6;
  z-index: 95;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img2 .item-img .item-img-content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img2 .item-img .item-img-content .item-title {
  font-size: 0.125rem;
  color: #fff;
  text-align: center;
  padding: 0.104167rem 0.166667rem;
  border: 0.005208rem solid #fff;
  border-radius: 0.052083rem;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img3 {
  position: relative;
  width: 3rem;
  height: 1.75rem;
  margin-bottom: 0.166667rem;
  cursor: pointer;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img3 #imgUrl {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img3 .item-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img3 .item-img .item-img-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #2d2e33;
  opacity: .6;
  z-index: 95;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img3 .item-img .item-img-content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img3 .item-img .item-img-content .item-title {
  font-size: 0.125rem;
  color: #fff;
  text-align: center;
  padding: 0.104167rem 0.166667rem;
  border: 0.005208rem solid #fff;
  border-radius: 0.052083rem;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img4 {
  position: relative;
  width: 3rem;
  height: 1.458333rem;
  cursor: pointer;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img4 #imgUrl {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img4 .item-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img4 .item-img .item-img-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #2d2e33;
  opacity: .6;
  z-index: 95;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img4 .item-img .item-img-content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-center .list-center-img4 .item-img .item-img-content .item-title {
  font-size: 0.125rem;
  color: #fff;
  text-align: center;
  padding: 0.104167rem 0.166667rem;
  border: 0.005208rem solid #fff;
  border-radius: 0.052083rem;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right {
  display: flex;
  flex-direction: column;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img1 {
  position: relative;
  width: 3rem;
  height: 3.34375rem;
  margin-bottom: 0.166667rem;
  cursor: pointer;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img1 #imgUrl {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img1 .item-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img1 .item-img .item-img-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #2d2e33;
  opacity: .6;
  z-index: 95;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img1 .item-img .item-img-content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img1 .item-img .item-img-content .item-title {
  font-size: 0.125rem;
  color: #fff;
  text-align: center;
  padding: 0.104167rem 0.166667rem;
  border: 0.005208rem solid #fff;
  border-radius: 0.052083rem;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img2 {
  position: relative;
  width: 3rem;
  height: 2.645833rem;
  margin-bottom: 0.166667rem;
  cursor: pointer;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img2 #imgUrl {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img2 .item-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img2 .item-img .item-img-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #2d2e33;
  opacity: .6;
  z-index: 95;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img2 .item-img .item-img-content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img2 .item-img .item-img-content .item-title {
  font-size: 0.125rem;
  color: #fff;
  text-align: center;
  padding: 0.104167rem 0.166667rem;
  border: 0.005208rem solid #fff;
  border-radius: 0.052083rem;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img3 {
  position: relative;
  width: 3rem;
  height: 1.875rem;
  cursor: pointer;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img3 #imgUrl {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img3 .item-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img3 .item-img .item-img-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #2d2e33;
  opacity: .6;
  z-index: 95;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img3 .item-img .item-img-content {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.wholeBoxs .wholeBoxs-content .wholeBoxs-content-list .list-right .list-right-img3 .item-img .item-img-content .item-title {
  font-size: 0.125rem;
  color: #fff;
  text-align: center;
  padding: 0.104167rem 0.166667rem;
  border: 0.005208rem solid #fff;
  border-radius: 0.052083rem;
}

.wholeBoxs .whole {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wholeBoxs .whole .whole-back {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .8;
  z-index: 95;
}

@media (max-width: 1440px) {
  .wholeBoxs .whole .whole-back-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99;
  }

  .wholeBoxs .whole .whole-back-content .back-icon {
    width: 0.416667rem;
    height: 0.416667rem;
    display: flex;
    cursor: pointer;
  }

  .wholeBoxs .whole .whole-back-content .back-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .wholeBoxs .whole .whole-back-content .back-img {
    margin-top: 0.15625rem;
    width: calc(100% - 2.083333rem);
    display: flex;
  }

  .wholeBoxs .whole .whole-back-content .back-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: 1440px) {
  .wholeBoxs .whole .whole-back-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99;
  }

  .wholeBoxs .whole .whole-back-content .back-icon {
    width: 0.416667rem;
    height: 0.416667rem;
    display: flex;
    cursor: pointer;
  }

  .wholeBoxs .whole .whole-back-content .back-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .wholeBoxs .whole .whole-back-content .back-img {
    margin-top: 0.15625rem;
    width: calc(100% - 2.083333rem);
    display: flex;
  }

  .wholeBoxs .whole .whole-back-content .back-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}