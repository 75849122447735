.image {
  height: 1.5625rem;
  width: 2.145833rem;
  margin-right: 0.166667rem;
  flex: 0 0 auto;
}

.slideshow {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 5.020833rem;
  width: 10rem;
  padding-bottom: 0.78125rem;
  box-sizing: content-box;
}

.images {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  -webkit-animation: slideshow 90s linear infinite;
  animation: slideshow 90s linear infinite;
}

.images:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

@-webkit-keyframes slideshow {
  0% {
    left: 0;
  }

  100% {
    left: -41.625rem;
  }
}

@keyframes slideshow {
  0% {
    left: 0;
  }

  100% {
    left: -41.625rem;
  }
}