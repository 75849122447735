.popular {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.popular .popular-content {
  width: 7.5rem;
}

.popular .popular-content .popular-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popular .popular-content .popular-header .popular-header-icon {
  width: 2.145833rem;
  height: 0.5rem;
  display: flex;
}

.popular .popular-content .popular-header .popular-header-icon img {
  width: 100%;
  height: 100%;
}

.popular .popular-content .popular-content-header {
  margin-top: 0.270833rem;
  margin-bottom: 0.260417rem;
  display: flex;
  justify-content: center;
}

.popular .popular-content .popular-content-header .header-menu {
  display: flex;
  align-items: center;
}

.popular .popular-content .popular-content-header .header-menu .header-menu-item {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.popular .popular-content .popular-content-header .header-menu .header-menu-item .item-title {
  font-size: 0.104167rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #73757d;
  padding: 0 0 0.125rem 0;
  box-sizing: border-box;
}

.popular .popular-content .popular-content-header .header-menu .header-menu-item .item-title-active {
  font-weight: bold;
  color: #2d2e33;
  border-bottom: 0.020833rem solid #ff946b;
}

.popular .popular-content .popular-content-header .header-menu .header-menu-item .item-line {
  font-size: 0.104167rem;
  color: #bcbec6;
  margin: 0 0.15625rem 0.125rem 0.15625rem;
}

.popular .popular-content .popular-content-footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 0.833333rem;
}

.popular .popular-content .popular-content-footer .footer-item {
  width: 1.208333rem;
  height: 1.208333rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.130208rem;
  margin-bottom: 0.130208rem;
  cursor: pointer;
  position: relative;
}

.popular .popular-content .popular-content-footer .footer-item #imageUrl {
  width: 1.208333rem;
  height: 1.208333rem;
}

.popular .popular-content .popular-content-footer .footer-item .footer-item-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 1.208333rem;
  height: 1.208333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(0.052083rem);
  backdrop-filter: blur(0.052083rem);
  background: rgba(255,255,255,.2);
  z-index: 999;
}

.popular .popular-content .popular-content-footer .footer-item .footer-item-back .back-title {
  max-width: 1.135417rem;
  margin: 0.052083rem 0.104167rem;
  padding: 0.0625rem 0.052083rem;
  border-radius: 0.041667rem;
  border: 0.005208rem solid #fff;
  font-size: 0.083333rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #fff;
  text-align: center;
}

.popular .popular-content .popular-content-footer .footer-item-active {
  margin-right: 0;
}

.popular .popular-back {
  position: absolute;
  right: 0;
  bottom: 0.421875rem;
  width: 6.302083rem;
  height: 1.104167rem;
}

.popular .popular-back img {
  width: 100%;
  height: 100%;
}