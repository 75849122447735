.head {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.416667rem;
  display: flex;
  background: #fff;
  z-index: 999;
  transition: "all 0.25s ease-in-out";
}

.head .head-content {
  margin: 0 auto;
  width: calc(100% - 0.9375rem);
  display: flex;
  justify-content: space-between;
}

.head .head-content .head-content-left {
  height: 0.416667rem;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.head .head-content .head-content-left .head-content-logo {
  width: 0.739583rem;
  height: 0.21875rem;
  display: flex;
  margin-right: 0.541667rem;
}

.head .head-content .head-content-left .head-content-logo img {
  width: 0.739583rem;
  height: 0.21875rem;
}

.head .head-content .head-content-left .head-content-url {
  display: flex;
  align-items: center;
  height: 0.416667rem;
}

.head .head-content .head-content-left .head-content-url .url-item {
  display: flex;
  align-items: center;
}

.head .head-content .head-content-left .head-content-url .url-item .url-item-title {
  height: 0.416667rem;
  line-height: 0.416667rem;
  font-size: 0.09375rem;
  font-family: PingFangSC-Medium,PingFang SC;
  font-weight: 400;
  color: #2d2e33;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
}

.head .head-content .head-content-left .head-content-url .url-item .url-item-title-active {
  box-sizing: border-box;
  color: #fe5e10 !important;
  border-bottom: 0.020833rem solid #fe5e10;
}

.head .head-content .head-content-left .head-content-url .url-item .url-item-tips {
  font-size: 0.09375rem;
  color: #73757d;
  padding: 0 0.21875rem;
}

.head .head-content .head-content-tips {
  height: 0.416667rem;
  display: flex;
  align-items: center;
}

.head .head-content .head-content-tips .tips-phone {
  display: flex;
  align-items: center;
  margin-right: 0.3125rem;
}

.head .head-content .head-content-tips .tips-phone .tips-phone-icon {
  width: 0.125rem;
  height: 0.125rem;
  display: flex;
  margin-right: 0.052083rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.head .head-content .head-content-tips .tips-phone .tips-phone-icon img {
  width: 0.125rem;
  height: 0.125rem;
}

.head .head-content .head-content-tips .tips-phone .tips-phone-title {
  font-size: 0.083333rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #2d2e33;
}

.head .head-content .head-content-tips .tips-mail {
  display: flex;
  align-items: center;
}

.head .head-content .head-content-tips .tips-mail .tips-mail-icon {
  width: 0.125rem;
  height: 0.125rem;
  display: flex;
  margin-right: 0.052083rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.head .head-content .head-content-tips .tips-mail .tips-mail-icon img {
  width: 0.125rem;
  height: 0.125rem;
}

.head .head-content .head-content-tips .tips-mail .tips-mail-title {
  font-size: 0.083333rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #2d2e33;
}