.business {
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.business .business-top {
  display: flex;
  align-items: center;
  justify-content: center;
}

.business .business-top .business-top-icon {
  width: 2.145833rem;
  height: 0.5rem;
  display: flex;
}

.business .business-top .business-top-icon img {
  width: 100%;
  height: 100%;
}

.business .business-content {
  width: 100%;
  margin-bottom: 0.833333rem;
}

.business .business-content .menu-data {
  margin-top: 0.270833rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 99;
}

.business .business-content .menu-data .menu-data-left {
  display: flex;
  flex-direction: column;
  z-index: 99;
}

.business .business-content .menu-data .menu-data-left .menu-title {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.business .business-content .menu-data .menu-data-left .menu-title .menu-title-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.78125rem;
  height: 0.270833rem;
  border: 0.005208rem solid #fe5e10;
  font-size: 0.125rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #2d2e33;
  box-sizing: border-box;
  cursor: pointer;
}

.business .business-content .menu-data .menu-data-left .menu-title .menu-title-item-active {
  background: #fe5e10;
  color: #fff;
}

.business .business-content .menu-data .menu-data-left .menu-list {
  display: flex;
  flex-direction: column;
  margin-top: 0.416667rem;
}

.business .business-content .menu-data .menu-data-left .menu-list .menu-list-item {
  display: flex;
  margin-bottom: 0.328125rem;
  cursor: pointer;
  box-sizing: border-box;
}

.business .business-content .menu-data .menu-data-left .menu-list .menu-list-item .item-No {
  width: 0.125rem;
  font-size: 0.104167rem;
  font-family: PingFangSC-Semibold,PingFang SC;
  font-weight: bold;
  color: #2d2e33;
  padding-right: 0.208333rem;
  padding-bottom: 0.119792rem;
  border-bottom: 0.020833rem solid rgba(0,0,0,0);
  box-sizing: border-box;
}

.business .business-content .menu-data .menu-data-left .menu-list .menu-list-item .item-name {
  font-size: 0.104167rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #2d2e33;
  line-height: 0.145833rem;
}

.business .business-content .menu-data .menu-data-left .menu-list .menu-list-item-active {
  display: flex;
}

.business .business-content .menu-data .menu-data-left .menu-list .menu-list-item-active .item-No {
  width: 0.125rem;
  font-size: 0.104167rem;
  font-family: PingFangSC-Semibold,PingFang SC;
  font-weight: bold;
  color: #fe5e10;
  border-bottom: 0.020833rem solid #fe5e10;
  box-sizing: border-box;
}

.business .business-content .menu-data .menu-data-left .menu-list .menu-list-item-active .item-name {
  font-size: 0.104167rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #fe5e10;
  line-height: 0.145833rem;
}

.business .business-content .menu-list-img {
  width: 4.4375rem;
  height: 3.125rem;
  display: flex;
  z-index: 99;
}

.business .business-content .menu-list-img img {
  width: 4.4375rem;
  height: 3.125rem;
}

.business .business-content #imgUrl {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.business .busines-back {
  position: absolute;
  left: 0;
  bottom: 0.421875rem;
  width: 6.302083rem;
  height: 1.104167rem;
}

.business .busines-back img {
  width: 100%;
  height: 100%;
}