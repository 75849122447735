.strengthBased {
  width: 100%;
  display: flex;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.strengthBased .strengthBased-content {
  margin: 0 auto;
}

.strengthBased .strengthBased-content .strengthBased-content-header {
  margin-bottom: 0.270833rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.strengthBased .strengthBased-content .strengthBased-content-header .header-right-icon {
  width: 2.145833rem;
  height: 0.5rem;
  display: flex;
}

.strengthBased .strengthBased-content .strengthBased-content-header .header-right-icon img {
  width: 100%;
  height: 100%;
}

.strengthBased .strengthBased-content .strengthBased-list {
  display: flex;
  align-items: center;
  margin-bottom: 0.833333rem;
}

.strengthBased .strengthBased-content .strengthBased-list .list-item {
  width: 1.208333rem;
  height: 2.109375rem;
  margin: 0 0.057292rem;
  cursor: pointer;
  position: relative;
}

.strengthBased .strengthBased-content .strengthBased-list .list-item #ImgUrl {
  width: 100%;
  height: 100%;
}

.strengthBased .strengthBased-content .strengthBased-list .list-item .list-item-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  overflow: hidden;
}

.strengthBased .strengthBased-content .strengthBased-list .list-item .list-item-back .list-item-back-mack {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .4;
  z-index: 95;
  transition: all .3s ease-in-out;
}

.strengthBased .strengthBased-content .strengthBased-list .list-item .list-item-back .list-item-back-title {
  z-index: 99;
  width: 0.166667rem;
  font-size: 0.166667rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #fff;
}

.strengthBased .strengthBased-content .strengthBased-list .list-item .list-item-back .list-item-back-title-active {
  z-index: 99;
  width: 0.958333rem;
  font-size: 0.072917rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #fff;
}

.strengthBased .strengthBased-content .strengthBased-content-list {
  width: 100%;
  height: 3.40625rem;
  margin-top: 0.208333rem;
}

.strengthBased .strengthBased-content .strengthBased-content-list .list-hover {
  width: 100%;
  height: 3.40625rem;
  display: flex;
  list-style-type: none;
  padding: 0;
  box-sizing: border-box;
}

.strengthBased .strengthBased-content .strengthBased-content-list .list-hover .list-hover-item {
  transition: "all 0.4s ease-in-out";
  -webkit-transition: "all 0.4s ease-in-out";
  position: relative;
  width: 0.677083rem;
  height: 3.40625rem;
  background: #e8e8e8;
  display: flex;
  justify-content: center;
  border: 0.005208rem solid #fff;
  box-sizing: border-box;
  cursor: pointer;
}

.strengthBased .strengthBased-content .strengthBased-content-list .list-hover .list-hover-item .item-title {
  width: 1.072917rem;
  position: absolute;
  left: 0.34375rem;
  top: 0.291667rem;
  display: flex;
  align-items: center;
}

.strengthBased .strengthBased-content .strengthBased-content-list .list-hover .list-hover-item .item-title .item-title-No {
  width: 0.25rem;
  height: 0.25rem;
  display: flex;
  margin-right: 0.15625rem;
}

.strengthBased .strengthBased-content .strengthBased-content-list .list-hover .list-hover-item .item-title .item-title-No img {
  width: 0.25rem;
  height: 0.25rem;
  object-fit: cover;
}

.strengthBased .strengthBased-content .strengthBased-content-list .list-hover .list-hover-item .item-title .item-title-name {
  font-size: 0.166667rem;
  font-family: PingFangSC-Semibold,PingFang SC;
  font-weight: bold;
  color: #fff;
}

.strengthBased .strengthBased-content .strengthBased-content-list .list-hover .list-hover-item #ImgUrl {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.strengthBased .strengthBased-content .strengthBased-content-list .list-hover .list-hover-item .item-title-hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.291667rem;
}

.strengthBased .strengthBased-content .strengthBased-content-list .list-hover .list-hover-item .item-title-hover .item-title-hover-No {
  width: 0.1875rem;
  height: 0.1875rem;
  display: flex;
  margin-bottom: 1.416667rem;
}

.strengthBased .strengthBased-content .strengthBased-content-list .list-hover .list-hover-item .item-title-hover .item-title-hover-No img {
  width: 0.1875rem;
  height: 0.1875rem;
}

.strengthBased .strengthBased-content .strengthBased-content-list .list-hover .list-hover-item .item-title-hover .item-title-hover-No-active {
  margin-bottom: 1.588542rem;
}

.strengthBased .strengthBased-content .strengthBased-content-list .list-hover .list-hover-item .item-title-hover .item-title-hover-name {
  width: 0.125rem;
  font-size: 0.125rem;
  font-family: PingFangSC-Semibold,PingFang SC;
  font-weight: 600;
  color: #2d2e33;
  margin-bottom: 0.3125rem;
}

.strengthBased .strengthBased-content .strengthBased-content-list .list-hover .list-hover-item .item-title-hover .item-title-hover-icon {
  width: 0.21875rem;
  height: 0.21875rem;
  display: flex;
}

.strengthBased .strengthBased-content .strengthBased-content-list .list-hover .list-hover-item .item-title-hover .item-title-hover-icon img {
  width: 0.21875rem;
  height: 0.21875rem;
}

.strengthBased .strengthBased-content .strengthBased-content-list .list-hover .list-hover-item-active {
  transition: "all 0.4s ease-in-out";
  -webkit-transition: "all 0.4s ease-in-out";
  position: relative;
  width: 4.791667rem;
  height: 3.40625rem;
}

.strengthBased .strength-power {
  position: absolute;
  left: 0;
  bottom: 0.421875rem;
  width: 6.302083rem;
  height: 1.104167rem;
}

.strengthBased .strength-power img {
  width: 100%;
  height: 100%;
}

.strengthBased .strend {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.strengthBased .strend .strend-back {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .8;
  z-index: 95;
}

.strengthBased .strend .strend-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
}

.strengthBased .strend .strend-content .strend-content-icon {
  width: 0.3125rem;
  height: 0.3125rem;
  display: flex;
  cursor: pointer;
}

.strengthBased .strend .strend-content .strend-content-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.strengthBased .strend .strend-content .strend-content-list {
  margin-top: 0.15625rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 7.1875rem;
  height: 3.640625rem;
}

.strengthBased .strend .strend-content .strend-content-list .button-prev {
  position: absolute;
  left: -0.520833rem;
  top: calc(50% - 0.3125rem);
  width: 0.3125rem;
  height: 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../../../../assets/icon/prev-icon.png);
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.strengthBased .strend .strend-content .strend-content-list .button-prev:hover {
  background: url(../../../../assets/icon/prev-hover-icon.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.strengthBased .strend .strend-content .strend-content-list .button-next {
  position: absolute;
  right: -0.520833rem;
  top: calc(50% - 0.3125rem);
  width: 0.3125rem;
  height: 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: url(../../../../assets/icon/next-icon.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.strengthBased .strend .strend-content .strend-content-list .button-next:hover {
  background: url(../../../../assets/icon/next-hover-icon.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.strengthBased .strend .strend-content .strend-content-list .list-item {
  position: absolute;
  display: flex;
  width: 6.375rem;
  height: 3.640625rem;
  left: 0.40625rem;
  z-index: 3;
  transition: all .5s ease-in-out 0s;
}

.strengthBased .strend .strend-content .strend-content-list .list-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.strengthBased .strend .strend-content .strend-content-list .list-item-active {
  position: absolute;
  display: flex;
  width: 5.052083rem;
  height: 2.841823rem;
  left: 0;
  z-index: 1;
  transition: all .5s ease-in-out 0s;
}

.strengthBased .strend .strend-content .strend-content-list .list-item-active img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.strengthBased .strend .strend-content .strend-content-list .list-item-next {
  position: absolute;
  display: flex;
  width: 5.052083rem;
  height: 2.841823rem;
  left: 1.979167rem;
  z-index: 0;
  transition: all .5s ease-in-out 0s;
}

.strengthBased .strend .strend-content .strend-content-list .list-item-next img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}