.culture {
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.culture .culture-top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.895833rem 0 0.270833rem 0;
}

.culture .culture-top .culture-top-icon {
  width: 2.145833rem;
  height: 0.5rem;
  display: flex;
}

.culture .culture-top .culture-top-icon img {
  width: 100%;
  height: 100%;
}

.culture .culture-list {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 0.833333rem;
}

.culture .culture-list .culture-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.213542rem;
}

.culture .culture-list .culture-list-item .culture-list-item-title {
  font-size: 0.291667rem;
  font-family: DINNeuzeitGroteskPro-Bold,DINNeuzeitGroteskPro;
  font-weight: bold;
  color: #fe5e10;
  margin-bottom: 0.104167rem;
}

.culture .culture-list .culture-list-item .culture-list-item-title span {
  margin-left: 0.041667rem;
  font-size: 0.104167rem;
  font-family: PingFangSC-Semibold,PingFang SC;
  font-weight: bold;
  color: #fe5e10;
}

.culture .culture-list .culture-list-item .culture-list-item-name {
  font-size: 0.104167rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #2d2e33;
  margin-bottom: 0.15625rem;
}

.culture .culture-list .culture-list-item .culture-list-item-describe {
  width: 1.302083rem;
  font-size: 0.072917rem;
  font-family: PingFangSC-Regular,PingFang SC;
  font-weight: 400;
  color: #73757d;
}

.culture .culture-back {
  position: absolute;
  right: 0;
  top: 0.307292rem;
  width: 6.302083rem;
  height: 1.104167rem;
}

.culture .culture-back img {
  width: 100%;
  height: 100%;
}