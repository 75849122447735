.animationWrapper {
  margin-top: -2.1875rem;
}

#static_animation {
  width: 10rem;
  max-width: 100%;
  margin-top: 0.416667rem;
  z-index: 10;
}

#slogan img {
  position: relative;
}

#scrolling_animation {
  z-index: -1;
  opacity: 0;
  width: 10rem;
  height: 4.895833rem;
  max-width: 100%;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 0 50%;
  margin-bottom: 0.833333rem;
}