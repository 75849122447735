.selectCase {
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.selectCase .selectCase-top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.270833rem;
}

.selectCase .selectCase-top .selectCase-top-icon {
  width: 2.145833rem;
  height: 0.5rem;
  display: flex;
}

.selectCase .selectCase-top .selectCase-top-icon img {
  width: 100%;
  height: 100%;
}

.selectCase .selectCase-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.28125rem;
}

.selectCase .selectCase-list .selectCase-list-top {
  display: flex;
  align-items: center;
  margin-bottom: 0.125rem;
}

.selectCase .selectCase-list .selectCase-list-top .top-item1 {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.125rem;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-top .top-item1 img {
  width: 100%;
  height: 100%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-top .top-item1:hover {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-top .top-item1:hover img {
  width: 105%;
  height: 105%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-top .top-item2 {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.125rem;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-top .top-item2 img {
  width: 100%;
  height: 100%;
  transition: all .5s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-top .top-item2:hover {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.125rem;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-top .top-item2:hover img {
  width: 105%;
  height: 105%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-top .top-item3 {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-top .top-item3 img {
  width: 100%;
  height: 100%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-top .top-item3:hover {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-top .top-item3:hover img {
  width: 105%;
  height: 105%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-center {
  display: flex;
  align-items: center;
  margin-bottom: 0.125rem;
}

.selectCase .selectCase-list .selectCase-list-center .center-item1 {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.125rem;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-center .center-item1 img {
  width: 100%;
  height: 100%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-center .center-item1:hover {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.125rem;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-center .center-item1:hover img {
  width: 105%;
  height: 105%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-center .center-item2 {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.125rem;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-center .center-item2 img {
  width: 100%;
  height: 100%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-center .center-item2:hover {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.125rem;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-center .center-item2:hover img {
  width: 105%;
  height: 105%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-center .center-item3 {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-center .center-item3 img {
  width: 100%;
  height: 100%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-center .center-item3:hover {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-center .center-item3:hover img {
  width: 105%;
  height: 105%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-foot {
  display: flex;
  align-items: center;
}

.selectCase .selectCase-list .selectCase-list-foot .foot-item1 {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.125rem;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-foot .foot-item1 img {
  width: 100%;
  height: 100%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-foot .foot-item1:hover {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.125rem;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-foot .foot-item1:hover img {
  width: 105%;
  height: 105%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-foot .foot-item2 {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.125rem;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-foot .foot-item2 img {
  width: 100%;
  height: 100%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-foot .foot-item2:hover {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.125rem;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-foot .foot-item2:hover img {
  width: 105%;
  height: 105%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-foot .foot-item3 {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-foot .foot-item3 img {
  width: 100%;
  height: 100%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-list .selectCase-list-foot .foot-item3:hover {
  width: 2.083333rem;
  height: 1.385417rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.selectCase .selectCase-list .selectCase-list-foot .foot-item3:hover img {
  width: 105%;
  height: 105%;
  transition: all .2s ease-in-out;
}

.selectCase .selectCase-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectCase .selectCase-footer .selectCase-footer-icon {
  width: 0.791667rem;
  height: 0.260417rem;
  display: flex;
  margin: 0 0 0.729167rem 0;
  background: url(../../../../assets/icon/more.png);
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  cursor: pointer;
  z-index: 99;
  transition: all .5s ease-in-out;
}

.selectCase .selectCase-footer .selectCase-footer-icon-active {
  width: 0.791667rem;
  height: 0.260417rem;
  display: flex;
  margin: 0 0 0.729167rem 0;
  background: url(../../../../assets/icon/more-hover.png);
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  cursor: pointer;
  z-index: 99;
  transition: all .5s ease-in-out;
}

.selectCase .selectCase-back {
  position: absolute;
  right: 0;
  bottom: 0.708333rem;
  width: 6.302083rem;
  height: 1.104167rem;
}

.selectCase .selectCase-back img {
  width: 100%;
  height: 100%;
}