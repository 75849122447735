html,
#root,
body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  zoom: 1;
}

body {
  overflow-x: auto;
}

body {
  overflow-x: hidden;
}

div {
  box-sizing: border-box;
}