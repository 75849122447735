.customer-wall {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.customer-wall .customer-wall-icon {
  width: 2.145833rem;
  height: 0.5rem;
  display: flex;
  margin-bottom: 0.338542rem;
}

.customer-wall .customer-wall-icon img {
  width: 100%;
  height: 100%;
}

.customer-wall .customer-wall-back {
  width: 6.5rem;
  height: 3.984375rem;
  display: flex;
  margin-bottom: 1.015625rem;
}

.customer-wall .customer-wall-back img {
  width: 100%;
  height: 100%;
}

.customer-wall .customer-wall-coop {
  position: absolute;
  left: 0;
  bottom: 0.40625rem;
  width: 6.302083rem;
  height: 1.104167rem;
}

.customer-wall .customer-wall-coop img {
  width: 100%;
  height: 100%;
}