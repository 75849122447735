.swiper,
.swiper-container {
  height: 100%;
  width: 100%;
}

.swiper-pagination {
  text-align: end !important;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  width: 100% !important;
  height: auto !important;
  right: 0 !important;
  top: auto !important;
  bottom: 0.260417rem !important;
  left: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-pagination-bullet {
  margin: 0 0.104167rem !important;
  width: auto !important;
  height: 0.166667rem !important;
  font-size: 0.072917rem;
  font-family: PingFangSC-Semibold,PingFang SC;
  font-weight: bold;
  color: #fff;
  border-radius: 0 !important;
  opacity: 1 !important;
  text-align: center;
  background: rgba(0,0,0,0) !important;
  box-sizing: border-box !important;
}

.swiper-pagination-bullet-active {
  background: rgba(0,0,0,0) !important;
  border-bottom: 0.010417rem solid #fe5e10;
  color: #fe5e10 !important;
  outline: none !important;
}

.swiper-slide {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev {
  width: 0.3125rem !important;
  height: 0.3125rem !important;
  left: 0.46875rem !important;
  bottom: auto !important;
  top: calc(50% - 0.3125rem) !important;
  right: auto !important;
  background: url(../../../../assets/icon/left-icon.png);
  background-repeat: no-repeat;
  background-size: cover;
  outline: none;
  z-index: 999 !important;
}

.swiper-button-prev-active {
  display: block;
}

.swiper-button-prev::after {
  content: "" !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-button-next {
  width: 0.3125rem !important;
  height: 0.3125rem !important;
  right: 0.46875rem !important;
  bottom: auto !important;
  top: calc(50% - 0.3125rem) !important;
  left: auto !important;
  background: url(../../../../assets/icon/right-icon.png);
  background-repeat: no-repeat;
  background-size: cover;
  outline: none;
  z-index: 999 !important;
}

.swiper-button-next-active {
  display: block;
}

.swiper-button-next::after {
  content: "" !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "" !important;
}

:focus {
  outline: 0;
}

.banner {
  width: 100%;
  height: 4.208333rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.banner .banner-content {
  width: 100%;
  height: 100%;
  position: relative;
}

.banner .banner-content .banner-icon {
  position: absolute;
  top: calc(50vh - 0.489583rem);
  left: 0.46875rem;
  width: 0.088542rem;
  height: 0.489583rem;
  background-image: url(../../../../assets/icon/banner-icon.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 999;
}

.banner .banner-content .banner-tips {
  position: absolute;
  bottom: 0.3125rem;
  left: 0.46875rem;
  display: flex;
  align-items: center;
  z-index: 999;
}

.banner .banner-content .banner-tips .banner-tips-left {
  width: 0.166667rem;
  height: 0.166667rem;
  background-image: url(../../../../assets/icon/banner-left-icon.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 0.260417rem;
}

.banner .banner-content .banner-tips .banner-tips-center {
  width: 0.166667rem;
  height: 0.166667rem;
  background-image: url(../../../../assets/icon/banner-center-icon.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 0.260417rem;
}

.banner .banner-content .banner-tips .banner-tips-right {
  width: 0.166667rem;
  height: 0.166667rem;
  background-image: url(../../../../assets/icon/banner-right-icon.png);
  background-repeat: no-repeat;
  background-size: cover;
}